<script setup lang="ts">
import { useProgrammatic } from '@oruga-ui/oruga-next';
import { useAppStore } from '~/stores/app';
import { useUserStore } from '~/stores/user';

import AccountChangePassword from '~/components/modals/AccountChangePassword.vue';
import AccountEMailAddress from '~/components/modals/AccountEMailAddress.vue';
import UserManagement from '~/components/modals/UserManagement.vue';
import SetupTotp from '~/components/modals/SetupTotpModal.vue';

interface Props {
  size?: string
}
const props = withDefaults(defineProps<Props>(), {
  size: 'normal',
});
const appStore = useAppStore();
const userStore = useUserStore();
const oruga = useProgrammatic();

const router = useRouter();

function logout() {
  router.push('/logout');
}

function openResetModal() {
  oruga.oruga.modal.open({
    component: AccountChangePassword,
    props: {
      icon: 'i-fa6-solid-key',
      size: 'medium',
    },
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
    animation: 'fade',
  });
}

function openEmailModal() {
  oruga.oruga.modal.open({
    props: {
      icon: 'i-fa6-solid-envelope',
      size: 'medium',
    },
    component: AccountEMailAddress,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
    animation: 'fade',
  });
}

function openUserManagmentModal() {
  oruga.oruga.modal.open({
    component: UserManagement,
    props: {
      title: 'Benutzer suchen',
      icon: 'i-fa6-solid-users',
      size: 'large',
    },
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
    animation: 'fade',
    width: 'auto',
  });
}

function openSetupTotpModal() {
  oruga.oruga.modal.open({
    props: {
      title: 'Zwei-Faktor-Authentifizierung',
      icon: 'i-fa6-solid-lock',
      size: 'medium',
    },
    component: SetupTotp,
    hasModalCard: true,
    trapFocus: true,
    canCancel: false,
    scroll: 'keep',
    animation: 'fade',
  });
}

function toggleAdmin() {
  appStore.toggleAdmin();

  if (appStore.adminMode)
    router.push('/admin');
  else
    router.push('/');
}

const groups = (userStore.getGroups() || []) as string[];

const adminGroups = import.meta.env.VITE_ADMIN_GROUPS as string;
const passwordGroups = import.meta.env.VITE_PASSWORD_GROUPS as string;

const hasGroupAdmin = groups.some(v => adminGroups.split(',').includes(v.trim()));
const hasGroupPassword = groups.some(v => passwordGroups.split(',').includes(v.trim()));
</script>

<template>
  <VDropdown
    placement="left-start"
    :distance="6"
  >
    <div
      v-if="props.size === 'normal'"
      class="h-12 w-12 flex cursor-pointer items-center self-center justify-center border-3 border-light-200 rounded-full bg-gray-200 text-center drop-shadow-lg anim-scale-95"
    >
      <div
        class="i-fa6-solid-user h-7 w-7 text-gray-700"
      />
    </div>
    <div
      v-else
      class="mr-1 mt-1 h-8 w-8 flex cursor-pointer items-center self-center justify-center border-3 border-light-200 rounded-full bg-gray-200 text-center drop-shadow-lg anim-scale-95"
    >
      <div
        class="i-fa6-solid-user h-5 w-5 text-gray-700"
      />
    </div>

    <template #popper="{ hide }">
      <div class="z-5 flex flex-col rounded-xl bg-gray-100 text-left drop-shadow-lg bg-opacity-95">
        <div class="menu-header rounded-t-xl">
          Profileinstellungen
        </div>
        <a v-if="!userStore" class="menu-item rounded-b-xl">
          <div class="i-fa6-solid-user self-center" /> Login
        </a>
        <div v-else class="menu-item" @click="logout()">
          <div class="i-fa6-solid-user" />{{ userStore.user?.username }} ausloggen
        </div>
        <template v-if="userStore.isAuthenticated">
          <div class="menu-spacer" />
          <div class="menu-item" @click="hide(); openEmailModal()">
            <div class="i-fa6-solid-envelope self-center" /> Zweite E-Mail-Adresse ändern
          </div>
          <div class="menu-spacer" />
          <div v-if="userStore.user?.id !== ''" class="menu-item rounded-b-xl" @click="hide(); openResetModal()">
            <div class="i-fa6-solid-key self-center" />Passwort ändern
          </div>
          <div class="menu-spacer" />
          <div class="menu-item" @click="hide(); openSetupTotpModal()">
            <div class="i-fa6-solid-lock self-center" />Zwei-Faktor-Authentifizierung
          </div>
          <template v-if="hasGroupPassword">
            <div class="menu-header">
              Benutzerverwaltung
            </div>
            <div class="menu-item rounded-b-xl" @click="hide(); openUserManagmentModal()">
              <div class="i-fa6-solid-magnifying-glass self-center" /> Benutzer suchen
            </div>
          </template>
          <template v-if="hasGroupAdmin">
            <div class="menu-header">
              Portal editieren
            </div>
            <div class="menu-item rounded-b-xl" @click="hide(); toggleAdmin()">
              <div class="i-fa6-solid-pen-to-square self-center" />Editiermodus {{ appStore.adminMode ? 'ausschalten' : 'einschalten' }}
            </div>
          </template>
        </template>
      </div>
    </template>
  </VDropdown>
</template>

<style>
  .menu-header {
    @apply py-1 px-4 bg-light-primary text-light-200;
  }

  .menu-item {
    @apply cursor-pointer py-2 px-4 bg-gray-100 hover:bg-white flex flex-row gap-2;
  }

  .menu-spacer {
    @apply border-dotted border-t-1 border-gray-400
  }
</style>
